// Import Bootstrap functions, variables, and mixins first
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Now, redefine the problematic variable using Bootstrap's color system
$body-secondary-color: rgba($body-color, .75);

// Import the rest of Bootstrap
@import "~bootstrap/scss/bootstrap";

// Custom styles
@import "custom";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

// Instead, you can use Bootstrap's built-in variables:
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Now you can use Bootstrap's variables and functions
$body-secondary-color: rgba($body-color, .75);
