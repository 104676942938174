body {
  margin: 0;
  font-family: 'at-hauss';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.widget-body {
  background-color: #B9D3DC;
}

.widget-header {
  background-color: rgba( 51, 63, 72, 0.8);
}

.card-body-with-bg-image {
  background-image:url('./assets/images/background-image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main-text {
  font-weight: 300;
  font-size: 30px;
  font-family: 'at-hauss';
}

.normal-text {
  font-size: 20px;
  font-family: 'at-hauss';
}

.custom-card {
  background-color: #f8f9fa; // Example background color
  border: 1px solid #ced4da; // Example border
  border-radius: 0.5rem; // Example border radius
  padding: 1rem; // Example padding
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); 
}

.card-text {
  font-size: 1.25rem;
  color: #000003;
  margin-bottom: 1rem;
  font-family: 'at-hauss';
}


.main-button:before {
  border: pxToRem(1px) solid rgb(var(--secondary-color));
  border-radius: .5rem;
  content: "";
  -webkit-mask-image: linear-gradient(var(--buttonangle), transparent 0, #000 100%);
  mask-image: linear-gradient(var(--buttonangle), transparent 0, #000 100%);
  position: absolute;
  top: pxToRem(-1px);
  bottom: pxToRem(-1px);
  left: pxToRem(-1px);
  right: pxToRem(-1px);
  transition: --buttonangle .3s cubic-bezier(.77, 0, .175, 1);
  z-index: 1
}

.main-button {
  align-items: center;
  color: #fff;
  border: pxToRem(1px) solid rgb(var(--primary-color));
  border-radius: .5rem;
  display: inline-flex;
  align-items: center;
  justify-self: center;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1;
  padding: .625rem 1.125rem;
  position: relative;
  text-decoration: none;
  transition: all .2s ease-in-out;
  white-space: nowrap;

  &.small {
    font-size: 0.75rem;
    padding: 0.6rem 1.25rem;
  }

  &.medium {
    font-size: 0.875rem;
    padding: 0.625rem 1.125rem;
  }

  &.large {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }

  @media (min-width: 768px) {
    &.small {
      font-size: 0.875rem;
      padding: 0.6rem 1.25rem;
    }

    &.medium {
      font-size: 1.125rem;
      padding: 1rem 1.75rem;
    }

    &.large {
      font-size: 1.25rem;
      padding: 1.25rem 2rem;
    }
  }
}

.main-button:hover {
  background: #000;
  box-shadow: 0 0 1.5rem 0 hsla(0, 0%, 100%, .2);
}

.main-button:hover:before {
  --buttonangle: 0deg
}

.main-button.primary {
  background: #fff;
  color: #000;
}

.main-button.primary:hover {
  background: #000;
  color: #fff;
  box-shadow: 0 0 1.5rem 0 hsla(0, 0%, 100%, .2);
}

.main-button.tertiary {
  background: linear-gradient(90deg,
      #00D2FF 0%,
      #2E8DF8 40%,
      #594CF1 81%,
      #6B33EF 100%);
}

.main-button.tertiary:hover {
  background: #000;
  box-shadow: 0 0 1.5rem 0 hsla(0, 0%, 100%, .2);
}


.gradient-icon {
  background: linear-gradient(270deg, #6B33EF 0%, #00D2FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: inline-block;
}

.borderRadius{
  border-radius: 5px;
}
.bi-envelope-fill{
  color:#6BA4B8;
}
.bi-telephone-fill{
  color:#6BA4B8;
  text-align: center;
}

@include media-breakpoint-up(md) {
  span.info{
    font-size: 1.02rem;
  }
}
@include media-breakpoint-up(lg) {
  span.info{
    font-size: 1.10rem;
  }
}
